<template>
  <div class="registerCompany">
    <!-- 匹配的公司 -->
    <div>
      <el-dialog :visible.sync="dialogVisible" width="500px" :title="titleTxt" append-to-body>
        <div class="registerCompanyContent">
          <div v-show="pageModel == 'write'" class="padding">
            <div class="companyName"></div>
            <el-form ref="companyNameForm" :rules="rules" :model="nameForm">
              <el-form-item prop="company_name">
                <CompanySelect v-model="nameForm.company_name"></CompanySelect>
              </el-form-item>
            </el-form>

            <div class="notice">
              <div>{{ $t("Precautions") }}{{ $t("colon") }}</div>
              <div>1.{{ $t("licenseofyourcompany") }}</div>
              <div>2.{{ $t("ensureaccuracy") }}</div>
            </div>
          </div>
          <div v-show="pageModel == 'list'">
            <div class="companyContent">
              <div
                class="companyDetail clearfix border"
                v-for="(it, index) in similarList"
                :key="index"
              >
                <div class="fl clearfix cursor" @click="handleClickCompany(it)">
                  <div class="fl logo">
                    <el-image
                      :src="it.logo ? it.logo : DEFAULT_LOGO"
                      class="img"
                      fit="scale-down"
                    ></el-image>
                  </div>
                  <div class="fl detail">
                    <div
                      class="name"
                      :title="it.name_en | priorFormat(it.name_zh, LOCALE)"
                    >
                      {{ it.name_en | priorFormat(it.name_zh, LOCALE) }}
                    </div>
                    <div
                      class="clearfix textOverflow"
                      
                    >
                      <div class="fl">
                        {{
                          it.country
                            | countryCityFormat(
                              it.region_en,
                              it.region_zh,
                              LOCALE
                            )
                        }}
                      </div>
                      <div
                        class="fr mainColor cursor"
                        style="margin-top: 30px"
                        v-if="it.status != 0"
                        @click="queryCompany(it)"
                      >
                        <slot name="btnRight"></slot>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="addCompany">
                <i
                  class="el-icon-plus icon mainColor cursor"
                  @click="createNewCompany(3)"
                ></i
                ><span @click="createNewCompany(3)" class="cursor">{{
                  $t("Addnewcompany")
                }}</span>
              </div>
            </div>
          </div>
          <div v-show="pageModel == 'create'" class="padding">
            <div>
              <el-form
                :label-position="'top'"
                label-width="80px"
                :rules="createRules"
                ref="createCompany"
                :model="createCompany"
              >
                <el-form-item :label="$t('companyName')" prop="companyName">
                  <el-input
                    v-model="createCompany.name"
                    size="medium"
                    :placeholder="$t('companyName')"
                    disabled
                    clearable
                  />
                </el-form-item>
                <el-form-item :label="$t('country')" prop="country">
                  <CountrySelect
                    v-model="createCompany.country"
                    placeTitle
                    size="medium"
                    :locale="LOCALE"
                  ></CountrySelect>
                </el-form-item>
                <el-form-item :label="$t('region')" prop="region">
                  <CityComplete
                    v-model="createCompany.region"
                    size="medium"
                    :countryId="createCompany.country"
                    placeTitle
                    :locale="LOCALE"
                  ></CityComplete>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div v-show="pageModel == 'join'" class="padding">
            <div class="joinPage">
              <div class="title1">{{ $t("Youareabouttojoin") }}</div>
              <div class="fl clearfix cursor companyDetail">
                <div class="fl logo">
                  <el-image
                    :src="
                      selectCompany.logo ? selectCompany.logo : DEFAULT_LOGO
                    "
                    class="img"
                    fit="scale-down"
                  ></el-image>
                </div>
                <div class="fl detail1">
                  <div class="textOverflow name">
                    {{
                      selectCompany.name_en
                        | priorFormat(selectCompany.name_zh, LOCALE)
                        | textFormat
                    }}
                  </div>
                  <!-- <div class="textOverflow fontColor">
                  {{
                    selectCompany.industry | industryFormat(LOCALE) | lineFormat
                  }}
                </div> -->
                  <div class="clearfix textOverflow">
                    <div class="fl">
                      {{
                        selectCompany.country
                          | countryCityFormat(
                            selectCompany.region_en,
                            selectCompany.region_zh,
                            LOCALE
                          )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <span
            class="back cursor"
            @click="backChange"
            v-show="pageModel != 'write'"
          >
            <i class="el-icon-arrow-left"></i>{{ $t("previous") }}
          </span>
          <el-button
            v-show="pageModel == 'write'"
            type="primary"
            size="small"
            style="width: 100px"
            :disabled="nameForm.company_name.length < 3"
            @click="handleCompany"
            :loading="nextLoading"
            >{{ $t("next") }}</el-button
          >
          <el-button
            size="small"
            type="primary"
            v-show="pageModel == 'create' || pageModel == 'join'"
            @click="confirmJoin"
            :loading="joinLoading"
            >{{ $t("Confirmtojoin") }}</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
/**
 * slot:
 *
 * 事件：
 * btnQueryChange 点击右边插槽按钮发射回来的事件,插槽不要写任何方法
 * confirmJoin:确认加入
 */

const replaceWords = [
  "网络",
  "科技",
  "供应链",
  "管理",
  "有限",
  "公司",
  "国际",
  "物流",
  "货运",
  "代理",
  "INTERNATIONAL",
  "TRANSPORTATION",
  "LOGISTICS",
  "FREIGHT",
  "FORWARDERS",
  "CO.,LTD",
  ".",
  "(",
  ")",
  "（",
  "）",
];
import CompanySelect from "~/baseComponents/CompanySelect";
import CountrySelect from "~/baseComponents/CountrySelect";
import CityComplete from "~/baseComponents/CityComplete";
import { priorFormat, lineFormat } from "~/basePlugins/filters";
export default {
  components: {
    CompanySelect,
    CountrySelect,
    CityComplete,
  },
  data() {
    return {
      allPageMode: "write",
      pageModel: "write",
      titleTxt: this.$t("inthenameofthe"),
      dialogVisible: false,
      joinLoading: false,
      nameForm: {
        company_name: "",
      },
      createCompany: {
        name: "",
        country: "",
        region: "",
      },
      selectCompany: {},
      similarList: [],
      nextLoading: false,
      isBtnShow: false,
      rules: {
        company_name: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback();
              return;
            } else {
              if (this.handleReplace(value) == "") {
                callback(new Error(this.$t("plsvaildName")));
              } else {
                callback();
              }
            }
          },
        },
      },
      createRules: {
        country: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "change",
        },
        region: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "change",
        },
      },
      backToWrite: false,
      unActiveId: null,
    };
  },
  watch: {
    "createCompany.country"() {
      this.$nextTick(() => {
        this.clearRedLine();
      });
    },
    pageModel(nv) {
      if (nv == "write") {
        this.titleTxt = this.$t("inthenameofthe");
      } else if (nv == "list") {
        this.titleTxt = this.$t("Pleasechooseyour");
      } else if (nv == "create") {
        this.titleTxt = this.$t("Improvebasiccompany");
      } else if (nv == "join") {
        this.titleTxt = this.$t("Confirmtojoin");
      }
    },
  },
  mounted() {},
  methods: {
    openPanel() {
      this.dialogVisible = true;
      this.pageModel = "write";
      this.unActiveId = null;
      this.selectCompany = {};
      this.backToWrite = false;
      this.createCompany = {
        name: "",
        country: "",
        region: "",
      };
    },
    closePanel() {
      this.dialogVisible = false;
      this.pageModel = "write";
      this.unActiveId = null;
      this.selectCompany = {};
      this.backToWrite = false;
      this.createCompany = {
        name: "",
        country: "",
        region: "",
      };
    },

    backChange() {
      if (this.pageModel == "list") {
        this.pageModel = "write";
      }
      if (this.pageModel == "create" || this.pageModel == "join") {
        if (this.backToWrite) {
          this.pageModel = "write";
        } else {
          this.pageModel = "list";
        }
      }
    },
    handleClickCompany(item) {
      if (item.related_id == 0 || item.status == 0) {
        this.handlefullCompanyInfo(item);
        this.pageModel = "create";
        this.clearRedLine();
        this.backToWrite = false;
      } else {
        this.selectCompany = item;
        this.pageModel = "join";
        this.backToWrite = false;
      }
    },
    async handleCompany() {
      this.$refs.companyNameForm.validate(async (valid) => {
        let result
        // 如果等于YMP，走玉漭的接口
        if (this.PJID === 'YMP') {
          result = await this.$store.dispatch(
              "API_ymp_company/company_associateSearch",
              {
                company_name: this.nameForm.company_name,
              }
          );
        } else {
          result = await this.$store.dispatch(
              "baseStore/company_associateSearch",
              {
                company_name: this.nameForm.company_name,
                source: this.PJSource,
              }
          );
        }
        if (!result.data.length) {
          this.createCompany.name = this.nameForm.company_name;
          this.pageModel = "create";
          this.clearRedLine();
          this.backToWrite = true;
        } else {
          let matchedData = result.data.find((item) => {
            //完全匹配公司
            return (
              item.name_en == this.nameForm.company_name ||
              item.name_zh == this.nameForm.company_name
            );
          });
          if (matchedData) {
            if (matchedData.related_id == 0 || matchedData.status == 0) {
              this.pageModel = "create";
              this.handlefullCompanyInfo(matchedData);
              this.clearRedLine();
              this.backToWrite = true;
            } else {
              this.selectCompany = matchedData;
              this.pageModel = "join";
              this.backToWrite = true;
              return;
            }
          } else {
            this.similarList = result.data;
            this.pageModel = "list";
          }
        }
      });
    },
    handlefullCompanyInfo(info) {
      this.unActiveId = info.company_id;
      this.createCompany = {
        name: priorFormat(info.name_en, info.name_zh, this.LOCALE),
        country: info.country,
        region: lineFormat(
          priorFormat(info.region_en, info.region_zh, this.LOCALE)
        ),
      };
    },
    clearRedLine() {
      this.$refs.createCompany.clearValidate();
    },
    createNewCompany() {
      this.unActiveId = null;
      this.createCompany = {
        name: this.nameForm.company_name,
      };
      this.backToWrite = false;
      this.pageModel = "create";
    },

    queryCompany(item) {
      this.$emit("btnQueryChange", item);
    },
    confirmJoin() {
      let params = {
        name: "",
        result: {},
      };
      if (this.pageModel == "create") {
        this.$refs.createCompany.validate((valid) => {
          if (valid) {
            params.name = this.createCompany.name;
            params.result[`country`] = this.createCompany.country;
            params.result[`region_${this.LOCALE}`] = this.createCompany.region;
            if (this.unActiveId) {
              params.result[`company_id`] = this.unActiveId;
            } else {
              params.result[
                `company_name_${this.LOCALE}`
              ] = this.createCompany.name;
            }
            this.$emit("confirmJoin", params);
            this.closePanel();
          }
        });
      }
      if (this.pageModel == "join") {
        params.name = priorFormat(
          this.selectCompany.name_en,
          this.selectCompany.name_zh,
          this.LOCALE
        );
        params.result[`company_id`] = this.selectCompany.company_id;
        this.$emit("confirmJoin", params);
        this.closePanel();
      }
    },
    handleReplace(string) {
      let _string = string;
      replaceWords.forEach((item) => {
        _string = _string.replace(item, "");
      });
      return _string;
    },
  },
};
</script>
<style lang="less" scoped>
// .registerCompany {
  .registerCompanyContent {
    height: 400px;
  }
  .companyName {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  .notice {
    div {
      color: #999;
      line-height: 20px;
    }
    margin-top: 30px;
  }
  .companyContent {
    overflow-y: auto;
    height: 360px;
    padding: 15px 30px;
    .addCompany {
      .icon {
        padding: 20px;
        border: 1px solid #ececec;
        border-radius: 10px;
        font-size: 20px;
        margin-right: 30px;
        margin-left: 5px;
      }
    }
  }
  .border {
    border-bottom: 1px solid #cececc;
  }
  .companyDetail {
    height: 80px;
    margin-bottom: 10px;
    .logo {
      width: 70px;
      height: 70px;
      border: 1px solid #ececec;
      padding: 4px;
      .img {
        width: 100%;
        height: 100%;
      }
    }
    .detail {
      width: 335px;
      margin-left: 15px;
      .name {
        font-weight: bold;
        font-size: 15px;
        height: 48px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
      }
      div {
        color: #666;
      }
      & > div {
        line-height: 24px;
        min-height: 10px;
      }
    }
    .detail1 {
      width: 340px;
      margin-left: 15px;
      & > div {
        line-height: 24px;
        min-height: 10px;
      }
      .name {
        font-weight: bold;
        font-size: 15px;
        height: 48px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
      }
    }
  }
  .joinPage {
    .title {
      font-weight: bold;
      font-size: 16px;
    }
    .title1 {
      color: #666;
      margin: 10px 0 20px;
    }
  }
  .back {
    margin-top: -15px;
    font-size: 14px;
    color: #999;
    position: absolute;
    left: 20px;
    bottom: 30px;
  }
  .fontColor {
    color: #666;
  }
  .padding {
    padding: 15px 30px;
  }
  /deep/.el-dialog__body {
    padding: 0;
    min-height: 180px;
  }
  /deep/.el-dialog__title {
    font-size: 16px;
    font-weight: bold;
    color: #303133;
  }
// }
</style>
